.grid:after {
  content: '';
  display: block;
  clear: both;
}

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item {
  float: left;
  min-height: 1px;
  box-sizing: border-box;
  width: 20%;
  padding: 5px;
}

.item--larger {
  width: 40%;
}

.item__image {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
}

.item__source {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  padding-top: 35px;
  color: #fff;
  text-decoration: none;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.55) 0, rgba(0, 0, 0, 0.35) 60%, transparent);
  text-shadow: 1px 1px #000;
}

.item__source img {
  flex: 0 0 auto;
  margin-right: 10px;
  border-radius: 50%;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.35);
}

.item__source > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item__source bdi {
  font-weight: bold;
  display: block;
}

.sizer {
  width: 20%;
  min-height: 1px;
}

@media screen and (max-width: 768px) {
  .item,
  .sizer {
    width: 25%;
  }

  .item--larger {
    width: 50%;
  }
}

@media screen and (max-width: 414px) {
  .item,
  .sizer {
    min-width: 100%;
  }
}

